import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

export const IconImage = styled.img`
  width: 100%;
  max-width: 4.5rem;
  height: auto;
  border-radius: 7px;
`;

export const NavLink = styled(props => <Link {...props} />)`
  flex-shrink: 1;
  padding: 0.5rem;
  transition: background-color 500ms ease;
  border-radius: 7px 7px 0px 0px;
  background-color: ${props => props.theme.backgroundColor};

  &.active:hover {
    background-color: ${props => props.theme.contentBackgroundColor};
  }

  &.active {
    background-color: ${props => props.theme.contentBackgroundColor};
  }

  :hover {
    background-color: ${props => props.theme.contentBackgroundColor};
  }
`;
