import React from "react";
import { StaticQuery, graphql } from "gatsby";

import TabIcon from "./TabIcon";
import { TabsBar } from "./TabbarStyle";

const Tabbar = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "product" } } }
          sort: { fields: [frontmatter___position], order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                id
                title
                path
              }
            }
          }
        }
      }
    `}
    render={data => (
      <TabsBar>
        {data.allMarkdownRemark.edges.map(product => (
          <TabIcon
            key={product.node.frontmatter.title}
            product={product.node.frontmatter}
          />
        ))}
      </TabsBar>
    )}
  />
);

export default Tabbar;
