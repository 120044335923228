import React from "react"
import { FooterContainer, Copyrights, FooterLink } from "./FooterStyle"

const Link = (props) => (
    <FooterLink href={props.to}>{props.children}</FooterLink>
)

const Footer = () => (
    <FooterContainer>
        <Copyrights>© 2006-{new Date().getFullYear()} InVooDoo. All Rights Reserved.</Copyrights>
        <>&nbsp;&nbsp;</>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <>&nbsp;&nbsp;</>|<>&nbsp;&nbsp;</>
        <Link to="/terms-of-use">Terms of Use</Link>
    </FooterContainer>
)

export default Footer