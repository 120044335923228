const theme = {
    backgroundColor: "#101010",
    textColor: "white",
    contentBackgroundColor: "rgba(255, 255, 255, 0.05)",
    footerColor: "#141414",
    linkColor: "#FFFFFF",
    linkHoveredColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: "10px",
    secondaryTextColor: "rgba(255, 255, 255, 0.5)",
    titleFont: "HelveticaNeue-Medium",
    textFont: "HelveticaNeue-Light",
    textThinFont: "HelveticaNeue-Thin"
}

export default theme