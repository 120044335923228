import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Tabbar from "../components/Tabbar/Tabbar"
import Footer from "../components/Footer/Footer"

import styled from "styled-components"

import theme from "../styles/theme-style"
import { ThemeProvider } from "styled-components"

export const ContentAreaContainer = styled.div`
  background-color: ${props => props.theme.contentBackgroundColor};
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render = { data => (
      <>
        <ThemeProvider theme={theme}>
          <>
            <Tabbar />
            <ContentAreaContainer>{children}</ContentAreaContainer>
            <Footer />
          </>
        </ThemeProvider>
      </>
    )}
  />
)

export default Layout
