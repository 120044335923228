import React from "react";
import { IconImage } from "./TabIconStyle";
import { NavLink } from "./TabIconStyle";

const TabIcon = ({ product }) => (
  <NavLink to={product.path} activeClassName='active'>
    <IconImage src={`/images/products/${product.id}.png`} />
  </NavLink>
);

export default TabIcon;
