import styled from "styled-components"

export const LatterList = styled.ol`
    list-style-type: upper-alpha;
`

export const LinkStyle = styled.a`
    font-size: ${props => props.fontSize};
    color: ${props => props.theme.linkColor};
    font-family: ${props => props.theme.textThinFont};

    &:link {
        color: ${props => props.theme.linkColor};
    }

    &:hover {
        color: ${props => props.theme.linkHoveredColor};
        text-decoration: none;
    }
`

