import { normalize } from 'styled-normalize'
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
    ${normalize}

    html {
        font-size: 62.5%;
    }

    body {
        font-family: "HelveticaNeue-Light", "HelveticaNeue-Medium", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Arial Narrow", Arial, "Lucida Grande", sans-serif; 
        font-weight: 300;        
        font-size: 1.6rem;
        -webkit-font-smoothing: antialiased;
        color: white;
        background-color: #101010;
    }

    ${'' /* a {
        color: #FFFFFF;
        font-size: 1.6rem;

        &:link {
            color: #FFFFFF;
        }

        &:hover {
            color: rgba(255, 255, 255, 0.7);
            text-decoration: none;
        }
    } */}
`

export default GlobalStyles