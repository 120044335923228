import styled from "styled-components"
import { LinkStyle } from "../Misc/MiscStyle"

export const FooterContainer = styled.div`
    background-color: ${props => props.theme.footerColor};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;

    @media (max-width: 600px), (max-height: 500px) {
        height: 30px;
    }
`

export const Copyrights = styled.span`
    font-size: 1.4rem;

    @media (max-width: 1000px) {
        font-size: 1.0rem;
    }
`
export const FooterLink = styled(LinkStyle)`
    font-size: 1.3rem;

    @media (max-width: 600px), (max-height: 500px) {
        font-size: 1.0rem;
    }
`